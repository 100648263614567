import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Observable, Subscription, map, startWith, switchMap } from 'rxjs';
import {  selectProductsState } from '../../state/products/products.reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { loadArticoli } from '../../state/products/products.actions';
import { ProductsState, ProductSearchFiltersClass, ProductsClass, ProductGroupClass } from '../../state/products/products.state';
import { CustomMatTableComponent, TableColumn } from '../custom-mat-table/custom-mat-table.component';
import { UtilsService } from '../../services/utils.service';


@Component({
  selector: 'app-products-table',
  standalone: true,
  imports: [CommonModule,CustomMatTableComponent],
  templateUrl: './products-table.component.html',
  styleUrl: './products-table.component.css'
})
export class ProductsTableComponent {
  @Output() infoSelezionata = new EventEmitter<string>();
  @Output() data = new EventEmitter<any>();

  products$ : Observable<ProductsState>;
  recordCount:number = 0;
  selectedElement:any;

  myColumns: TableColumn[] = [

    {
      columnDef: 'codiceEan',
      header: 'Codice EAN',
      cell: (element: any) => `${element.codiceEan}`,
      click: (element: any) => this.onCellClick(element)
    },
    {
      columnDef: 'descrizione',
      header: 'Descrizione',
      cell: (element: any) => `${element.descrizione}`,
      click: (element: any) => this.onCellClick(element)
    }
  ];


  onCellClick(element: any) {
    //console.log(`Clicked on ${element.codiceEan}`, element);
    this.selectedElement = element.codiceEan;
    let selectedInfo:string = `Caricamenti Codice EAN: ${element.codiceEan}`
    this.infoSelezionata.emit(selectedInfo);
    this.data.emit(element.storicoUploads);
    //this.store.dispatch(loadStoricoArticoli({ perPage:10, page: 1, idImport:element.idImport}));


  }

  handlePageEvent(event: PageEvent) {
    // Qui puoi gestire l'evento, ad esempio caricare nuovi dati in base alla pagina corrente
    this.store.dispatch(loadArticoli({ perPage: event.pageSize, page: event.pageIndex + 1,  filter:{codiceEan:"", idImport:"", inci:""} }));
  }

    constructor(private store: Store, private router: Router, private utilsService: UtilsService) {
      this.products$ = this.store.select(selectProductsState);
      //this.productDataFilter$ = this.store.select(selectPoductDataFilter);
    }

    dataSource = new MatTableDataSource<ProductGroupClass>();
    private subscriptions = new Subscription();

    ngOnInit() {


      this.subscriptions.add(this.products$.subscribe(data => {
          this.dataSource = new MatTableDataSource(data.productData.data);
          this.recordCount = data.productData.recordCount
      }));

      this.store.dispatch(loadArticoli({ perPage: 10, page: 1,  filter:{codiceEan:"", idImport:"", inci:""} }));

      // this.subscriptions.add(
      //   this.productDataFilter$.subscribe(filter => {
      //     this.store.dispatch(loadArticoli({ perPage:pageSize, page: 1,  idImport:filter.idImport}));
      //   }));


      let pageSize:number = 10;
      if (this.dataSource.paginator)
      {
         pageSize = this.dataSource.paginator!.pageSize;
      }

      //this.store.dispatch(loadArticoli({ perPage:pageSize, page: 1,  idImport:""}));
    }

    ngOnDestroy() {
      // Annulla la sottoscrizione quando il componente viene distrutto
      this.subscriptions.unsubscribe();
    }


}
