export interface ProductsState {
    loading:boolean;
    showError:boolean;
    errorMessage:string;
    historyUploadProducts: HistoryUploadProducts;
    HistoryUploadData : HistoryUploadData;
    productData:ProductData;
    storicoArticoloDataTable :storicoArticoloDataTableClass;
    productDataFilter: ProductSearchFiltersClass
    historyUploadProductsFilter:ProductSearchFiltersClass
    fornitoriInfo:FornitoreInfo[]
}


export interface storicoArticoloDataTableClass
{
  filter:StoricoSearchFiltersClass;
  data:StoricoUpload[];

}

export interface StoricoSearchFiltersClass { inci:string }

export interface ProductSearchFiltersClass { idImport? : string, inci?:string, codiceEan?:string}

export enum MessageBoxType {
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
  SUCCESS = "check_circle",
  YES_NO = "help"
}

export interface MessageBoxDetails {
  messageType: MessageBoxType;
  title: string;
  content: string;
  redirectUri?: string;
  autoCloseMilliseconds? : number;
}

export interface DataUploadClass {
    id:number
    codiceEan: string;
    descrizione: string;
    inci: string;
    fornitore: string;
    dataUpload: Date;

  }

  export interface ProductsClass {
    id:number
    codiceEan: string;
    descrizione: string;
    componente: string;
    fornitore: string;
    idUpload:number;

  }

  export interface  HistoryUploadProducts {
    recordCount : number,
    data:DataUploadClass[]

  }



  export interface  HistoryUploadData {
    recordCount : number,
    data:HistoryUploadClass[]
  }





  export interface StoricoUpload {
    idUpload: number;
    inci: string;
    dataUpload: string;
    descrizione:string;
    isValid:boolean;
  }

  export interface ProductGroupClass {
    id: number;
    codiceEan: string;
    descrizione: string;
    storicoUploads: StoricoUpload[];
  }


  // export interface ProductGroupClass {
  //     idUpload: number;
  //     inci: string;
  //     dataUpload: string;
  //   }


  export interface  ProductData {
    recordCount : number,
    data:ProductGroupClass[]
  }

  export interface GetArticoliGroupResponse {
    success: boolean;
    content: {
      data: ProductGroupClass[];
      recordCount: number;
    };
    error: string | null;
  }


  export interface ApiFornitoriInfoResponse {
    success: boolean;
    content: FornitoreInfo[];
    error: any;
  }
  
  export interface FornitoreInfo {
    codiceFornitore: string | null;
    email: string;
    numeroImportazioni: number;
    numeroArticoliInseriti: number;
    ruoli: string;
  }

  export interface ApiProductsResponse {
    success: boolean;
    content: ProductData,
    error: string | null;
  }


  export interface HistoryUploadClass
  {
    idImport: number;
    dataUpload: string;
    totalItems: number;
  }


  export interface StoricoUploadResponse {
    success: boolean;
    content: HistoryUploadData;
    error:  string | null;
  }


  export interface ApiProductsHistoryResponse {
    success: boolean;
    content: HistoryUploadProducts,
    error: string | null;
  }


export const InitialState:ProductsState = {
  storicoArticoloDataTable: { filter:{ inci:""}, data:[] },

  fornitoriInfo:[],

  HistoryUploadData:
  {

      data: [],
      recordCount : 0
  },
  historyUploadProducts:
    { data: [],
      recordCount : 0,
     }
    ,


  productData:
  { data: [],
    recordCount : 0,
  },
  productDataFilter:
  {
    inci:"",
    codiceEan:"",
    idImport : ""
  },

  historyUploadProductsFilter:
  {
    inci:"",
    codiceEan:"",
    idImport : ""
  },
  loading: false,
  showError:false,
  errorMessage:""
}


    // historyUploadData:
    // { data: [
    //   { id: 1, ean: '891123132', descrizione: "Shampo ", inc: 'H', fornitore:"Fornitore di test", dataUpload: new Date() },
    //   { id: 1, ean: '891123132', descrizione: "Shampo ", inc: 'H', fornitore:"Fornitore di test", dataUpload: new Date() },
    //   { id: 1, ean: '891123132', descrizione: "Shampo ", inc: 'H', fornitore:"Fornitore di test", dataUpload: new Date() }],
    //   recordCount : 3
    // },
