
   <div class="flex-container">
    <div class="left-table" style="padding: 10px 10px 10px 10px;">
      <!-- Tabella piccola a sinistra -->
      <mat-card >
        <mat-card-title>Lista Prodotti</mat-card-title>
        <mat-card-content>

          <form class="search-form">
            <mat-form-field class="">
              <mat-label>Ricerca EAN</mat-label>
              <input matInput [(ngModel)]="searchProductEAN" (ngModelChange)="updateSearch('product_ean', $event)" placeholder="Ex. 90008765432000" name="searchProductEAN">
            </mat-form-field>
            <!--mat-form-field class="">
              <mat-label>Ricerca INCI</mat-label>
              <input matInput [(ngModel)]="searchProductINCI" (ngModelChange)="updateSearch('product_inci', $event)" placeholder="Ex. Aqua, Glycerin" name="searchProductINCI">
            </!--mat-form-field-->
          </form>

          <app-products-table (infoSelezionata)="OnChangeIdImport($event)" (data)="OnClick($event)"></app-products-table>
          <!-- <app-upload-history-table (infoSelezionata)="OnChangeIdImport($event)"></app-upload-history-table> -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class="right-table" style="padding: 10px 10px 10px 10px;">
      <!-- Tabella grande a destra -->
      <mat-card *ngIf="selectedInfo !== 'NO_PRODUCTS'">
        <mat-card-title>{{selectedInfo}}</mat-card-title>

        <mat-card-content>

          <form class="search-form">
            <mat-form-field class="full-width">
              <mat-label>Ricerca INCI</mat-label>
              <input matInput [(ngModel)]="searchTextInciStoricoUpload" (ngModelChange)="updateSearch('storico_inci', $event)" name="searchTextInciStoricoUpload" placeholder="Ex. Aqua, Glycerin">
            </mat-form-field>
          </form>

            <app-products-uploads-table></app-products-uploads-table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>


<!-- <mat-grid-list cols="3" rowHeight="1.5:1">
    <mat-grid-tile colspan="1" ><app-upload-history-table></app-upload-history-table></mat-grid-tile>
    <mat-grid-tile  colspan="2" ><app-products-table></app-products-table></mat-grid-tile>
</mat-grid-list>
<div style="padding: 50px 50px 50px 50px;"></div> -->
