import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { login, loginSuccess, loginFailure, logout } from './auth.actions';
import { AuthState, initialState } from './auth.state';


export const selectAuthState = createFeatureSelector<AuthState>('auth');
export const selectAuthData = createSelector(
    selectAuthState, 
    (state: AuthState) => state 
  );



export const authReducer = createReducer(
  initialState,
  on(login, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(loginSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    user: user,
    isLogged:true,
    error: null
  })),
  on(loginFailure, (state, { error }) => ({
    ...state,
    loading: false,
    user: null,
    error: error,
    isLogged:false,
  })),
  on(logout, (state) => ({
    ...state,
    loading: false,
    user:null,
    error: null,
    isLogged:false
  })),
);