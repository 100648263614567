import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthState } from '../state/auth/auth.state';
import { Store } from '@ngrx/store';
import { logout } from '../state/auth/auth.actions';
import { navigate } from '../state/route/route.actions';





export const HttpErrorInterceptor: HttpInterceptorFn = (request, next) => {

    const store = inject(Store<AuthState>);
    const started = Date.now();
    const reqMethod = request.method;
    const reqUrl = request.urlWithParams;
    return next(request).pipe(
      catchError((error) => {
        console.log(error);
        if (error.status === 401) {
          console.log("ERRORE 401: Non autorizzato");
          // Emette un'azione di logout o di errore di autorizzazione
          store.dispatch(logout());
          store.dispatch(navigate({path:["/error", "401"]}));
        } else if (error.status === 404) {
          //console.log("ERRORE 404: Non trovato");
          // Qui puoi gestire l'errore 404 come necessario
          store.dispatch(navigate({path:["/error", "404"]}));
        }
        // Propaga l'errore
        return throwError(() => error);
        //return [];
      })
    );
  
  }

// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';
// import { Observable, catchError, tap, throwError } from 'rxjs';
// import { Store } from '@ngrx/store';
// import { logout } from '../state/auth/auth.actions';

// @Injectable()
// export class HttpErrorInterceptor implements HttpInterceptor {
    
    
//   constructor(private store: Store) {}


//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const started = Date.now();
//     const reqMethod = request.method;
//     const reqUrl = request.urlWithParams;

//     console.log(`Richiesta iniziata: ${reqMethod} ${reqUrl}`);

//     return next.handle(request).pipe(
//       tap(event => {
//         if (event instanceof HttpResponse) {
//           const elapsed = Date.now() - started;
//           console.log(`Richiesta completata: ${reqMethod} ${reqUrl} in ${elapsed} ms`, event);
//         }
//       }),
//       catchError((error: HttpErrorResponse) => {
//         const elapsed = Date.now() - started;
//         console.log(`Richiesta fallita: ${reqMethod} ${reqUrl} in ${elapsed} ms`, error.message);

//         if (error.status === 401) {
//           console.log("ERRORE 401: Non autorizzato");
//           // Emette un'azione di logout o di errore di autorizzazione
//           this.store.dispatch(logout());
//         } else if (error.status === 404) {
//           console.log("ERRORE 404: Non trovato");
//           // Qui puoi gestire l'errore 404 come necessario
//         }
//         // Propaga l'errore
//         return throwError(() => error);
//       })
//     );
//   }
// }
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       console.log("ERRORE");
        
  //       if (error.status === 401) {

  //         console.log("ERRORE 401!");
  //         // Emette un'azione di logout o di errore di autorizzazione
  //         this.store.dispatch(logout()); 
  //       } else if (error.status === 404) {

  //         console.log("ERRORE 404!");
  //         // Gestisci l'errore 404 come necessario
  //         // Per esempio, puoi reindirizzare l'utente a una pagina 404 personalizzata
  //         // o emettere un'azione specifica di NgRx
  //       }
  //       return throwError(() => error);
  //     })
  //   );
  // }
//}