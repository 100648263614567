export interface AuthState {
  loading: boolean;
  user: User | null;
  error: string | null;
  isLogged:boolean;

}



export interface ApiResponseLogin {
  success: boolean;
  content: UserContent | null;
  error: string | null;
}

export interface UserContent {
  id: string;
  username: string;
  email: string;
  phone_number: string;
  roles: string[];
  token: string;
  password: null;
  new_password: null;
}


export interface User {
  id: string;
  username: string;
  token:string;
}

export const initialState: AuthState = {
  loading: false,
  user: null,
  error: null,
  isLogged:false

};
