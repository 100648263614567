import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductsService } from '../../services/products.service';
import { handleError } from '../error/error.actions';
import { loadArticoliSuccess, loadArticoli, loadStoricoArticoliSuccess, loadStoricoArticoli, uploadArticoliRequest, uploadArticoliSuccess, loadStoricoUpload, loadStoricoUploadSuccess, updateFlagIsValid, updateFlagSuccess, loadFornitoriInfo, loadFornitoriInfoSuccess } from './products.actions';
import { DataUploadClass, HistoryUploadProducts, MessageBoxType, ProductData } from './products.state';
import { DialogService } from '../../services/dialog-service.service';
import { selectProductsState } from './products.reducers';
import { Store, select } from '@ngrx/store';

@Injectable()
export class ProductsEffects {
  constructor(private actions$: Actions, private productService: ProductsService, private dialogService: DialogService, private store: Store)  {}



  setFlagIsValid$ =createEffect(() =>
  this.actions$.pipe(
    ofType(updateFlagIsValid),
    mergeMap((action) =>
      this.productService.SetFlagIsValid(action.idImport,action.flagIsValid).pipe(
          map(response => {
              // Controlla il valore di `success` nella risposta
              if (response.success) {
                // Destruttura `content` per ottenere il `token` se l'autenticazione ha avuto successo
                //console.log(response.content)
                //console.log(action);
                return updateFlagSuccess({idImport: action.idImport, flagIsValid: action.flagIsValid});
              } else {
                // Se `success` è false, usa il messaggio di errore dalla risposta
                throw new Error(response.error || "Errore sconosciuto durante il caricamento dati.");
              }
            }),
          catchError(error => of(handleError({ error:"Errore caricamento dati articoli.", exception:error })))
        )
      )
    )
  );

  loadArticoli$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadArticoli),
      withLatestFrom(this.store.pipe(select(selectProductsState))), // Usa `withLatestFrom` per ottenere i dati dallo state
      mergeMap(([action, data]) => {
        // Ora puoi usare `action` e `yourSpecificStateData` per la tua chiamata API
        return this.productService.GetStoricoArticoliGroupByEan(action.perPage, action.page, data.productDataFilter).pipe(
          map(response => {
            //console.log(response);
            if (response.success) {
              return loadArticoliSuccess({result: response.content as ProductData});
            } else {
              throw new Error(response.error || "Errore sconosciuto durante il caricamento dati.");
            }
          }),
          catchError(error => of(handleError({ error: "Errore caricamento dati articoli.", exception: error })))
        );
      })
    )
  );
  // loadArticoli$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadArticoli),
  //     mergeMap((action) =>
  //       this.productService.GetStoricoArticoliGroupByEan(action.perPage,action.page, action.filter).pipe(
  //           map(response => {
  //               // Controlla il valore di `success` nella risposta
  //               console.log(response);
  //               if (response.success) {
  //                 // Destruttura `content` per ottenere il `token` se l'autenticazione ha avuto successo
  //                 return loadArticoliSuccess({result:response.content as ProductData});
  //               } else {
  //                 // Se `success` è false, usa il messaggio di errore dalla risposta
  //                 throw new Error(response.error || "Errore sconosciuto durante il caricamento dati.");
  //               }
  //             }),
  //           catchError(error => of(handleError({ error:"Errore caricamento dati articoli.", exception:error })))
  //       )
  //     )
  //   )
  // );

  loadStoricoArticoli$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadStoricoArticoli),
    withLatestFrom(this.store.pipe(select(selectProductsState))),
    mergeMap(([action, data]) =>
      this.productService.GetStoricoArticoli(action.perPage, action.page, data.historyUploadProductsFilter).pipe(
        map(response => {
          if (response.success) {
            return loadStoricoArticoliSuccess({result: response.content as HistoryUploadProducts});
          } else {
            throw new Error(response.error || "Errore sconosciuto durante il caricamento dati.");
          }
        }),
        catchError(error => of(handleError({ error: "Errore caricamento dati articoli.", exception: error })))
      )
    )
  )
);



  uploadArticoli$ = createEffect(() => this.actions$.pipe(
    ofType(uploadArticoliRequest),
    mergeMap((action) =>
      this.productService.UploadArticoli(action.articoli).pipe(
        map(response => uploadArticoliSuccess({ response })),
        catchError(error => of(handleError({ error:"Errore caricamento dati articoli.", exception:error })))
      )
    )
  ));

  uploadArticoliSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(uploadArticoliSuccess),
    tap(() => this.dialogService.openDialog({
        messageType:   MessageBoxType.SUCCESS,
        title: "Messaggio di successo",
        content: "Procedura avvenuta con successo fra pochi secondi potrai effettuare una nuova operazione.",
        redirectUri : "/products",
        autoCloseMilliseconds:3000
     }))
  ), { dispatch: false });


  // setFlagIsValidSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(updateFlagSuccess),
  //   tap(() => this.dialogService.openDialog({
  //       messageType:   MessageBoxType.SUCCESS,
  //       title: "Stato cambiato con successo",
  //       content: "Fra pochi secondi potrai effettuare una nuova operazione.",
  //       redirectUri : "",
  //       autoCloseMilliseconds:2000
  //    }))
  // ), { dispatch: false });

  

  loadStoricoUpload(perPage: number, page: number) {
    this.productService.GetStoricoUpload(perPage, page).subscribe(response => {
      if (response.success) {
        //console.log('Dati storici caricati:', response.content.data);
        // Gestisci i dati come necessario
 
      } else {
        console.error('Errore nel caricamento dei dati storici:', response.error);
        // Gestisci l'errore come necessario
      }
    });
  }

  loadStoricoUpload$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadStoricoUpload),
    mergeMap((action) =>
      this.productService.GetStoricoUpload(action.perPage,action.page).pipe(
          map(response => {
              // Controlla il valore di `success` nella risposta
              if (response.success) {
                console.log(response.content);
                return loadStoricoUploadSuccess({ response:response.content });
              } else {
                // Se `success` è false, usa il messaggio di errore dalla risposta
                throw new Error(response.error || "Errore sconosciuto durante il caricamento dati.");
              }
            }),
          catchError(error => of(handleError({ error:"Errore caricamento dati articoli.", exception:error })))
        )
      )
    )
  );


  loadFornitoriInfo$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadFornitoriInfo),
    mergeMap((action) =>
      this.productService.GetInfoFornitori().pipe(
          map(response => {
              // Controlla il valore di `success` nella risposta
              if (response.success) {
                console.log(response.content);
                return loadFornitoriInfoSuccess({ response });
              } else {
                // Se `success` è false, usa il messaggio di errore dalla risposta
                throw new Error(response.error || "Errore sconosciuto durante il caricamento dati.");
              }
            }),
          catchError(error => of(handleError({ error:"Errore caricamento dati fornitori info.", exception:error })))
        )
      )
    )
  );


}


