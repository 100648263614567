import { StoricoUpload } from './../../state/products/products.state';
import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { Observable, Subscription} from 'rxjs';
import {  selectProductsState } from '../../state/products/products.reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { loadArticoli, loadStoricoArticoli, loadStoricoArticoloDataTable } from '../../state/products/products.actions';
import { ProductsState } from '../../state/products/products.state';
import { CustomMatTableComponent, TableColumn } from '../custom-mat-table/custom-mat-table.component';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-products-uploads-table',
  standalone: true,
  imports: [CommonModule,CustomMatTableComponent],
  templateUrl: './products-uploads-table.component.html',
  styleUrl: './products-uploads-table.component.css'
})
export class ProductsUploadsTableComponent implements OnChanges{
  //dati selezionati nell'altra tabella
  //sarebbe da usare lo store invece che onchanges
  @Input() data : any;

  products$ : Observable<ProductsState>;
  recordCount:number = 0;
  dataSource = new MatTableDataSource<StoricoUpload>();
  private subscriptions = new Subscription();
  myColumns: TableColumn[] = [];
  idProduct = 0;

  constructor(private store: Store, private router: Router, private utilsService: UtilsService) {
    this.products$ = this.store.select(selectProductsState);
    //this.productDataFilter$ = this.store.select(selectPoductDataFilter);
    this.configTableColumn();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      //this.handleDataChange(this.data);
    }
  }

  private handleDataChange(data: any[]) {
    // Implementa qui la logica di aggiornamento per i nuovi dati
    // Ad esempio, aggiornare il dataSource per la tabella
    //this.dataSource.data = data;
  }

  ngOnInit() {
    this.subscriptions.add(this.products$.subscribe(data => {
        this.recordCount =  0;//data.storicoArticoloDataTable.data.length;
        this.dataSource = new MatTableDataSource(data.storicoArticoloDataTable.data);
        //controllo loadfin altrimenti va in errore se sta ancora caricando
        // if(!data.loading){
        //   console.log(data.productData.data[0].storicoUploads)

        //   if(data.productData.data[this.idProduct].storicoUploads){
        //     this.dataSource = new MatTableDataSource(data.productData.data[this.idProduct].storicoUploads);
        //     this.recordCount = data.productData.recordCount
        //   }
        // }
    }));

    this.store.dispatch(loadStoricoArticoloDataTable({ storicoArticoloDataTable:{ data :[], filter:{inci:""} } }));


    let pageSize:number = 5;
    if (this.dataSource.paginator)
    {
      console.log("IMPOSTO PAGINAZIONE")
       pageSize = this.dataSource.paginator!.pageSize;
    }
    else {

      //console.error("no paginator")
    }
    // let pageSize:number = 5;
    // if (this.dataSource.paginator)
    // {
    //   console.log("IMPOSTO PAGINAZIONE")
    //    pageSize = this.dataSource.paginator!.pageSize;
    // }
    //console.log("IMPOSTO PAGINAZIONE")
  }

  ngOnDestroy() {
    // Annulla la sottoscrizione quando il componente viene distrutto
    this.subscriptions.unsubscribe();
  }

  onCellClick(element: any) {
    console.log(`Clicked on ${element.columnName}`, element);

  }

  handlePageEvent(event: PageEvent) {
    // Qui puoi gestire l'evento, ad esempio caricare nuovi dati in base alla pagina corrente
    //this.store.dispatch(loadArticoli({ perPage: event.pageSize, page: event.pageIndex + 1,  idImport:""}));
  }
  

  /*
  idUpload: number;
    inci: string;
    dataUpload: string;
    descrizione:string;
  */
  configTableColumn(){
    this.myColumns = [
      {
        columnDef: 'idUpload',
        header: 'Id',
        cell: (element: any) => `${element.idUpload}`,
        //click: (element: any) => this.onCellClick(element, 'position')
      },
      {
        columnDef: 'dataUpload',
        header: 'Data Upload',
        cell: (element: any) => `${this.utilsService.formatDate(element.dataUpload)}`,
      },
      {
        columnDef: 'descrizione',
        header: 'Descrizione',
        cell: (element: any) => `${element.descrizione}`,
        cellClass: (element: any) => element.isValid ? 'valid-class' : 'invalid-class' 
      },
      {
        columnDef: 'inci',
        header: 'INCI',
        cell: (element: any) => `${element.inci}`,
        cellClass: (element: any) => element.isValid ? 'valid-class' : 'invalid-class' 
      },
      {
        columnDef: 'isValid',
        header: 'Valido',
        cell: (element: any) => `${element.isValid ? 'Sì' : 'No'}`, // Modifica il testo visualizzato in base al valore
        cellClass: (element: any) => element.isValid ? 'valid-class' : 'invalid-class' 
      }
    ];
  }


}
