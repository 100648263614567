import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ProductsTableComponent } from "../../components/products-table/products-table.component";
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { UploadExcelComponent } from "../../components/upload-excel/upload-excel.component";
import { UploadHistoryTableComponent } from '../../components/upload-history-table/upload-history-table.component';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { selectProductsState } from '../../state/products/products.reducers';
import { Observable } from 'rxjs';
import { ProductsState, MessageBoxType } from '../../state/products/products.state';
import { AddProductsComponent } from "../../components/add-products/add-products.component";

@Component({
  selector: 'app-import',
  standalone: true,
  imports: [RouterModule, CommonModule, MatToolbarModule, MatIconModule,
    MatButtonModule, MatMenuModule, MatSidenavModule, MatListModule,
    ProductsTableComponent, UploadExcelComponent, UploadHistoryTableComponent,
    MessageBoxComponent, AddProductsComponent],
  templateUrl: './import.component.html',
  styleUrl: './import.component.css'
})
export class ImportComponent {
  MessageBoxType = MessageBoxType;
  products$:Observable<ProductsState>
  importMode: string = '';
  constructor(private store: Store,  private router: Router) {

      this.products$ = this.store.select(selectProductsState);
      this.importMode = '';
  }
  setImportMode(mode: string): void {
    this.importMode = mode;
  }
  resetImportMode(): void {
    this.importMode = ''; // Resetta lo stato iniziale
  }
}
