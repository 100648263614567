import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiFornitoriInfoResponse, ApiProductsHistoryResponse, ApiProductsResponse, GetArticoliGroupResponse, ProductSearchFiltersClass, ProductsState, StoricoUploadResponse } from '../state/products/products.state';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { selectProductsState } from '../state/products/products.reducers';
import { AuthState } from '../state/auth/auth.state';
import { selectAuthData } from '../state/auth/auth.reducer';

@Injectable({ providedIn: 'root' })
export class ProductsService {

  baseUri =`${environment.apiUrlVendorHub}`;
  apiVersion = "/api/v1/";
  token:string="";

  private subscription = new Subscription();
  auth$ : Observable<AuthState>;
  constructor(private http: HttpClient, private store: Store) {


    this.auth$ = this.store.select(selectAuthData);
    this.subscription = this.auth$.subscribe(data => {
        this.token = data.user?.token!;
    });
  }


  getHeader() {
    console.log(localStorage.getItem('accessToken'));
    return new HttpHeaders({
      Authorization: `Bearer ${this.token}`
    });
  }

  GetArticoli(perPage:number, page:number, idImport:string): Observable<any> {
    //console.log(localStorage.getItem('accessToken'));
    //console.log(perPage);
    let url = `${this.baseUri}${this.apiVersion}VendorHub/GetArticoli?perPage=${perPage}&page=${page}&idImport=${idImport}`;
    return this.http.get<ApiProductsResponse>(url, { headers: this.getHeader()}); // Sostituisci 'URL_API' con l'URL effettivo
  }


  GetInfoFornitori(): Observable<ApiFornitoriInfoResponse> {
    const url = `${this.baseUri}${this.apiVersion}VendorHub/GetInfoFornitori`;
    return this.http.get<ApiFornitoriInfoResponse>(url, { headers: this.getHeader()}); // Sostituisci 'URL_API' con l'URL effettivo
  }


  GetStoricoArticoli(perPage:number, page:number, filter:ProductSearchFiltersClass): Observable<any> {
    let codiceEan:string = "";
    if (filter.codiceEan!.length>0)
    {
      codiceEan=`&codiceEan=${filter.codiceEan}`;
    }
    let inci:string = "";
    if (filter.inci!.length>0)
    {
      inci=`&inci=${filter.inci}`;
    }
    let url = `${this.baseUri}${this.apiVersion}VendorHub/GetStoricoArticoli?perPage=${perPage}&page=${page}&idImport=${filter.idImport}${codiceEan}${inci}`;
    return this.http.get<ApiProductsHistoryResponse>(url, { headers : this.getHeader() }); // Sostituisci 'URL_API' con l'URL effettivo
  }


  UploadArticoli(articoli: any[]): Observable<any> {
    let url = `${this.baseUri}${this.apiVersion}VendorHub/UploadArticoli`;
    return this.http.post(url, { articoliUpload: articoli }, { headers: this.getHeader()});
  }

  GetStoricoUpload(perPage: number, page: number): Observable<StoricoUploadResponse> {
    let url = `${this.baseUri}${this.apiVersion}VendorHub/GetStoricoUpload?perPage=${perPage}&page=${page}`;
    return this.http.get<StoricoUploadResponse>(url, { headers: this.getHeader() });
  }


  exportData(tableName: string): Observable<any> {
    let url = `${this.baseUri}${this.apiVersion}VendorHub/ExportData`;
    return this.http.post(url, {tableName:tableName}, { headers: this.getHeader()});
  }

  GetStoricoArticoliGroupByEan(perPage: number, page: number, filter:ProductSearchFiltersClass): Observable<GetArticoliGroupResponse> {

    let codiceEan:string = "";

    if (filter.codiceEan!.length>0)
    {
      codiceEan=`&codiceEan=${filter.codiceEan}`;
    }
    let inci:string = "";
    if (filter.inci!.length>0)
    {
      inci=`&inci=${filter.inci}`;
    }
    let url = `${this.baseUri}${this.apiVersion}VendorHub/GetStoricoArticoliGroupByEan?perPage=${perPage}&page=${page}${codiceEan}${inci}`;
    return this.http.get<GetArticoliGroupResponse>(url, { headers: this.getHeader() });
  }

  SetFlagIsValid(idImport: number, flagIsValid: boolean): Observable<any> {
    let url = `${this.baseUri}${this.apiVersion}VendorHub/UpdateFlagIsValid`;
    return this.http.post(url, {idImport, flagIsValid}, { headers: this.getHeader()});
  }



}
