import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { loadArticoli, loadArticoliSuccess, loadFornitoriInfo, loadFornitoriInfoSuccess, loadStoricoArticoli, loadStoricoArticoliSuccess, loadStoricoArticoloDataTable, loadStoricoUpload, loadStoricoUploadSuccess, resetArticoli, updateFlagSuccess, uploadArticoliRequest } from "./products.actions";
import { ProductsState, InitialState, ProductSearchFiltersClass } from "./products.state";



//let test:Date = new Date();

export const selectProductsState = createFeatureSelector<ProductsState>('products');
export const selectProductsData = createSelector(
    selectProductsState,
    (state: ProductsState) => state
  );

export const productsReducer = createReducer(
InitialState,
on(loadArticoli, (state, action) => {
  
  //preservo i filtri
  const refreshedFilter = {
    ...state.productDataFilter, // Copia prima tutte le proprietà dal primo oggetto
    ...action.filter, // Poi copia (e sovrascrive se necessario) tutte le proprietà dal secondo oggetto
  };

  
  return {
    ...state,
    productDataFilter:refreshedFilter,
    loading: true
  };


}),
on(loadArticoliSuccess, (state, action) => ({ ...state, productData : action.result, loading:false})),
on(resetArticoli, (state) => ( { ...state, productData : { data: [], recordCount : 0}, loading:false, productDataFilter: {idImport: "", codiceEan:"", inci:""}})),
on(uploadArticoliRequest, (state) => ({ ...state, loading:true })),

on(loadStoricoArticoli, (state, action) => {
  
  //preservo i filtri
  const refreshedFilter = {
    ...state.historyUploadProductsFilter, // Copia prima tutte le proprietà dal primo oggetto
    ...action.filter, // Poi copia (e sovrascrive se necessario) tutte le proprietà dal secondo oggetto
  };

  
  return {
    ...state,
    historyUploadProductsFilter:refreshedFilter,
    loading: true
  };


}),

on(loadStoricoArticoliSuccess, (state, action) => ({ ...state,  historyUploadProducts: action.result, loading:false})),
on(loadStoricoUploadSuccess, (state, action) => ({ ...state,  HistoryUploadData:action.response, loading:false})),
on(loadStoricoArticoloDataTable, (state, action) => {

    let filteredData = action.storicoArticoloDataTable.data;

    if (action.storicoArticoloDataTable.filter && action.storicoArticoloDataTable.filter.inci.trim() !== '') {
      // Filtra i dati in base al valore del filtro 'inci'
      // console.log(action.storicoArticoloDataTable.filter.inci);
      filteredData = action.storicoArticoloDataTable.data.filter(item =>
      item.inci.toLowerCase().includes(action.storicoArticoloDataTable.filter.inci.toLowerCase())
    );
  }
  
  return {
    ...state,
    storicoArticoloDataTable: {
      ...action.storicoArticoloDataTable,
      // Aggiorna solo i dati filtrati
      data: filteredData
    },
    loading: false
  };
}),

on(updateFlagSuccess, (state, action) => ({
  ...state,
  HistoryUploadData: {
    ...state.HistoryUploadData, 
    data: state.HistoryUploadData.data.map(importItem =>
      importItem.idImport === action.idImport
        ? { ...importItem, flagIsValid: action.flagIsValid }
        : importItem
    )
  }
})),
on(loadFornitoriInfo, (state, action) => ({ ...state,   loading:true})),
on(loadFornitoriInfoSuccess, (state, action) => ({ ...state,  fornitoriInfo: action.response.content, loading:false})),
// on(updateFlagSuccess, (state, action) => ({
//   ...state,
//   imports: state.storicoArticoloDataTable.data.map(importItem =>
//     importItem.idUpload === action.idImport
//       ? { ...importItem, flagIsValid: action.flagIsValid }
//       : importItem
//   ),

// })),
  

  

// on(uploadData, (state) => ({ ...state,  loading:true,  uploadData: [   { id: 1, ean: '891123132', descrizione: "Shampo ", inc: 'H', fornitore:"Fornitore di test", dataUpload: test },
// { id: 1, ean: '891123132', descrizione: "Shampo ", inc: 'H', fornitore:"Fornitore di test", dataUpload: test },
// { id: 1, ean: '891123132', descrizione: "Shampo ", inc: 'H', fornitore:"Fornitore di test", dataUpload: test }]})),
);
