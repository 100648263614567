<div class="articoli-table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="codiceEan">
      <th mat-header-cell *matHeaderCellDef> Codice EAN </th>
      <td mat-cell *matCellDef="let element">
        <input matInput placeholder="Codice Ean" [(ngModel)]="element.codiceEan">
      </td>
    </ng-container>

    <ng-container matColumnDef="descrizione">
      <th mat-header-cell *matHeaderCellDef> Descrizione </th>
      <td mat-cell *matCellDef="let element">
        <input matInput placeholder="Descrizione" [(ngModel)]="element.descrizione">
      </td>
    </ng-container>

    <!-- Colonna INCI -->
    <ng-container matColumnDef="inci">
      <th mat-header-cell *matHeaderCellDef> INCI </th>
      <td mat-cell *matCellDef="let element">
        <input #inputElement matInput placeholder="INCI" [value]="element.inci" (focus)="apriDialogInci(element, inputElement)" readonly class="input-focusable">
      </td>
    </ng-container>

    <ng-container matColumnDef="azioneCancella">
      <th mat-header-cell *matHeaderCellDef> Azioni </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="rimuoviRiga(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <button mat-raised-button color="primary" (click)="aggiungiRiga()" style="margin-top: 20px;">
    Aggiungi Riga
  </button>
  <button mat-raised-button color="accent" (click)="generateJsonFromSelections()" style="margin-top: 20px;">
    Importa Articoli
  </button>
</div>
