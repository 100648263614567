<div class="flex-container">
  <div class="left-table" style="padding: 10px 10px 10px 10px;">
    <!-- Tabella piccola a sinistra -->
    <mat-card >
      <mat-card-title>Seleziona Import</mat-card-title>
      <mat-card-content>
  
          <app-upload-history-table (infoSelezionata)="OnChangeIdImport($event)"></app-upload-history-table>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="right-table" style="padding: 10px 10px 10px 10px;">
    <!-- Tabella grande a destra -->
    <mat-card>
      <mat-card-title>{{selectedInfo}}</mat-card-title>
      <mat-card-content>


        <form class="search-form" class="full-width">
          <mat-form-field>
            <mat-label>Ricerca INCI</mat-label>
            <input matInput [(ngModel)]="searchTextInciStoricoUpload" (ngModelChange)="updateSearch('storico_inci', $event)" name="searchTextInciStoricoUpload" placeholder="Ex. Aqua, Glycerin">
          </mat-form-field>

          <mat-form-field style="margin-left: 10px;">
            <mat-label>Ricerca CodiceEan</mat-label>
            <input matInput [(ngModel)]="searchTextEanStoricoUpload" (ngModelChange)="updateSearch('storico_ean', $event)" name="searchTextEanStoricoUpload" placeholder="Ex. 90008765432001">
          </mat-form-field>
        </form>

        <app-upload-history-products></app-upload-history-products>
      </mat-card-content>
    </mat-card>
    <!-- <button (click)="testUpdateIsValid()">TEST UPDATE IS VALID</button> -->
  </div>
</div>
