import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
// import { TableColumn } from './table-column'; // Assumi che l'interfaccia sia definita qui

export interface TableColumn {
  columnDef: string;
  header: string;
  cell: (element: any) => string;
  click?: (element: any) => void; // Aggiunta di un gestore di click opzionale
  cellClass?:any;
}


@Component({
  selector: 'app-custom-mat-table',
  templateUrl: './custom-mat-table.component.html',
  styleUrls: ['./custom-mat-table.component.css'],
  standalone: true,
  imports: [MatPaginator,MatProgressBarModule,MatTableModule,CommonModule ],
})
export class CustomMatTableComponent implements OnInit {
  @Input() columns: TableColumn[] = [];
  @Input() isLoading: Boolean = false;
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @Input() recordCount: number = 0;
  @Input() selectedElement: any = {};
  @Input() hidePageSize: boolean = false;
  @Input() pageSizes: number[] = [5, 10, 20];
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns:string[] = [];

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.dataSource.paginator = this.paginator;
  }
}
