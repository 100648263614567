import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { navigate } from './route.actions';

@Injectable({ providedIn: 'root' })
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigate),
        tap(({ path, queryParams }) => this.router.navigate(path, { queryParams }))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router) {

    
  }
}
