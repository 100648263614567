import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import * as XLSX from 'xlsx';
import { selectProductsState } from '../../state/products/products.reducers';
import { Observable } from 'rxjs';
import { ProductsState } from '../../state/products/products.state';
import { Store } from '@ngrx/store';
import { uploadArticoliRequest } from '../../state/products/products.actions';

@Component({
  selector: 'app-upload-excel',
  standalone: true,
  imports: [MatTableModule, MatInputModule, MatFormFieldModule, CommonModule, MatSelectModule, MatButtonModule, MatProgressBarModule, MatCheckboxModule],
  templateUrl: './upload-excel.component.html',
  styleUrl: './upload-excel.component.css'
})
export class UploadExcelComponent {
  rows: any[] = [];
  maxSize = 0;
  opzioni = [
    {selezionato:false, value:"codiceEan", descr:"codice Ean"},
    {selezionato:false, value:"descrizione", descr:"descrizione"},
    {selezionato:false, value:"inci", descr:"inci"}
  ]

  // Oggetto per tenere traccia delle selezioni correnti per ogni colonna
  selezioni: Map<number, string> = new Map();
  isLoading = false;
  importAvailable = false;
  hasHeaderExcel = false;

  products$ : Observable<ProductsState>;
  constructor(private store: Store) {
    this.products$ = this.store.select(selectProductsState);
    }

  counter(max: number): number[] {
    return Array.from({length: max}, (_, i) => i);
  }

  toggleIntestazione() {
    // Opzionalmente, qui puoi aggiungere logica aggiuntiva se necessario quando l'utente cambia l'opzione dell'intestazione
    this.hasHeaderExcel = !this.hasHeaderExcel
  }

  onSelectChange(colIndex: number, evt:any) {
    colIndex = colIndex - 1;
    this.importAvailable = false;
    console.log(`Colonna ${colIndex} selezione cambiata: ${evt.value}`);
    let selectedValue = evt.value;
    const prevSelectedValue = this.selezioni.get(colIndex);
    if (prevSelectedValue) {
        const prevOption = this.opzioni.find(opzione => opzione.value === prevSelectedValue);
        if (prevOption) prevOption.selezionato = false;
    }

    this.opzioni.forEach(opzione => {
        if (opzione.value === selectedValue) {
            opzione.selezionato = true;
        }
        //faccio vedere il bottone se sono tutti true
        this.importAvailable = this.opzioni.every(opzione => opzione.selezionato);
    });

    this.selezioni.set(colIndex, selectedValue);
  }

  onFileChange(evt: any): void {
    this.isLoading = true
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
      console.log(data)
      this.rows = data;
      // Utilizza reduce() per trovare la dimensione massima degli array interni
      this.maxSize = this.rows.reduce((max, currentArray) => {
        return currentArray.length > max ? currentArray.length : max;
      }, 0); // Inizia con un valore massimo di 0

      console.log(this.maxSize); // Questo stamperà la dimensione massima trovata
      // Dimensiona tutti gli array come quello massimo
      const resizedArrays = this.rows.map(array => {
        // Crea un nuovo array della dimensione massima, riempiendolo con valori predefiniti
        const newArray = new Array(this.maxSize).fill("");
        // Copia i valori esistenti nell'array ridimensionato
        array.forEach((item: any, index: number) => newArray[index] = item);
        return newArray;
      });
      this.rows = resizedArrays;
      this.isLoading = false;

      if(this.maxSize == 3){
        this.selezioni.set(0, this.opzioni[0].value);
        this.selezioni.set(1, this.opzioni[1].value);
        this.selezioni.set(2, this.opzioni[2].value);
        this.importAvailable = true;
      }

    };
    reader.readAsBinaryString(target.files[0]);

    //una volta terminata devo controllare se il max row è 3 allora non chiedo i campi


  }

  //sostituire any con un modello
  generateJsonFromSelections(): any {
    const jsonResult = this.rows.slice(1).map(row => {
      let obj: any = {};
      this.selezioni.forEach((value, colIndex) => {
          obj[value] = row[colIndex] || "";
      });
      return obj;
  });
    const jsonConNome = { Prodotti : jsonResult};
    console.log(jsonConNome);
    //TODO TESTARE
    this.store.dispatch(uploadArticoliRequest({articoli: jsonResult}));

    return jsonConNome;
  }
}
