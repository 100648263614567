<div class="login-container">
  <mat-card class="login-card">
    <mat-card-header>
      <!-- <mat-card-title>Login</mat-card-title> -->
    </mat-card-header>
    <mat-card-content>
      <div style="margin-bottom:10px; text-align: center;">
      <img  style="width: 95%;" src="../../../assets/logo.svg" alt="LOGO MELONI">
    </div>   
      <form>
        <mat-form-field appearance="fill" class="login-input">
          <mat-label>Username</mat-label>
          <input matInput [(ngModel)]="username" name="username" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="login-input">
          <mat-label>Password</mat-label>
          <input matInput [(ngModel)]="password" name="password" type="password" required>
        </mat-form-field>
        <button mat-raised-button color="accent" style="width: 100%;" (click)="login()">Login</button>      
      </form>
      <br>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar  *ngIf="(auth$ | async)?.loading!"   mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
  
</div>
