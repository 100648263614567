import { ApplicationConfig, ErrorHandler, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { authReducer } from './state/auth/auth.reducer';
import { AuthEffects } from './state/auth/auth.effects';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ErrorEffects } from './state/error/error.effect';
import { productsReducer as productsReducer } from './state/products/products.reducers';
import { ProductsEffects } from './state/products/products.effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { RouterEffects } from './state/route/route.effects';
import { HttpErrorInterceptor } from './interceptors/HttpErrorInterceptor';
import { GlobalErrorHandler } from './interceptors/GlobalErrorInterceptor';



export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth'], // specifica le chiavi dello stato da sincronizzare
    rehydrate: true,
  })(reducer);
}

export const appConfig: ApplicationConfig = {
  providers: [
        //GESTIONE DELL'errore 404 e 401
     // Qui includi HttpClientModule per abilitare HttpClient nel tuo progetto
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },

    provideHttpClient(withFetch(), withInterceptors([HttpErrorInterceptor])),
    provideRouter(routes),
    provideStore({
      auth: authReducer,
      products: productsReducer,
      router: routerReducer, // Aggiunge il routerReducer allo stato dello store
    }, {
      metaReducers: [localStorageSyncReducer],
      runtimeChecks: { // Qui aggiungi i runtime checks
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true,
        strictStateImmutability: true,
      }
    }),
    provideEffects([AuthEffects, ErrorEffects, ProductsEffects, RouterEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideAnimationsAsync(),

    provideRouterStore()
  ]
};


