export interface ErrorItemClass {
    title:string,
    message:string
    code:string
  }

  export interface ErrorItemsClass  {
    [code: string]: ErrorItemClass;
  };

  export const ErrorItems:ErrorItemsClass = {
    "400": {
      code: "400",
      title: "Richiesta Non Valida",
      message: "La richiesta non può essere elaborata a causa di sintassi non valida."
    },
    "401": {
      code: "401",
      title: "Accesso Negato",
      message: "Il token è scaduto o le tue credenziali non sono valide. Non sei autorizzato a visualizzare questa pagina. Effettua nuovamente il login per continuare."
    },
    "403": {
      code: "403",
      title: "Accesso Vietato",
      message: "Non hai il permesso di accedere a questa risorsa."
    },
    "404": {
      code: "404",
      title: "Pagina non Trovata",
      message: "La pagina che stai cercando non esiste. Controlla l'URL e riprova."
    },
    "500": {
      code: "500",
      title: "Errore Interno del Server",
      message: "Si è verificato un errore interno del server. Riprova più tardi."
    },
    "502": {
      code: "502",
      title: "Bad Gateway",
      message: "Il server ha ricevuto una risposta non valida da un server upstream."
    },
    "503": {
      code: "503",
      title: "Servizio Non Disponibile",
      message: "Il server non è al momento disponibile. Potrebbe essere sovraccarico o in manutenzione."
    },
    "504": {
      code: "504",
      title: "Gateway Timeout",
      message: "Il server ha impiegato troppo tempo a rispondere e la connessione è stata chiusa."
    }
  };
  
