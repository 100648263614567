<mat-card style="max-width: 50%;margin:auto;margin-top: 3%;">
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="centered-content">
        <mat-icon [ngClass]="{
            'custom-info-icon': messageType === MessageBoxType.INFO,
            'custom-warning-icon': messageType === MessageBoxType.WARNING,
            'custom-error-icon': messageType === MessageBoxType.ERROR,
            'custom-success-icon': messageType === MessageBoxType.SUCCESS
          }" aria-hidden="false" [attr.aria-label]="messageType.toString()">{{messageType.toString()}}</mat-icon>
      </div>
        <p>{{content}}</p>
        <mat-divider></mat-divider>
      </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button (click) = "buttonClick()">Continua</button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
</mat-card>
  