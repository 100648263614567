import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { login, loginFailure, loginSuccess, showSnackbar } from './auth.actions';
import { handleError } from '../error/error.actions';
import { navigate } from '../route/route.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private snackBar: MatSnackBar
  ) {}

    showSnackbar$ = createEffect(() =>
    this.actions$.pipe(
        ofType(showSnackbar),
        tap((action) => {
            this.snackBar.open(action.message, 'Close', { duration: 3000,    panelClass: ['mat-toolbar', 'mat-primary'] });
        })
    ),
    { dispatch: false }
    );


  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action) =>
        this.authService.authenticate(action.username, action.password).pipe(
            map(response => {
                // Controlla il valore di `success` nella risposta
                if (response.success) {
                  // Destruttura `content` per ottenere il `token` se l'autenticazione ha avuto successo
                  localStorage.setItem('accessToken', response.content?.token!);
                  return loginSuccess({user: { id:"", username:"", token:response.content?.token! }});
                } else {
                  // Se `success` è false, usa il messaggio di errore dalla risposta
                  throw new Error(response.error || "Errore sconosciuto durante l'autenticazione.");
                }
              }),
              catchError(error => {
                // Crea un array con tutte le azioni che vuoi dispatchare
                const actionsToDispatch = [
                  handleError({ error: 'Login fallito!', exception: error }),
                  loginFailure({ error: 'Errore al momento del login'})
                ];
                
                // Usa `from` per convertire l'array di azioni in un Observable
                // e `switchMap` per "passare" da catchError a un nuovo Observable di azioni
                return from(actionsToDispatch);
              })
              //catchError(error => of(handleError({ error: 'Login fallito!', exception:error })))
        )
      )
    )
  );

  authenticateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      tap(() =>  this.store.dispatch(navigate({ path: ['/dashboard'] })))//this.routernavigate(['/dashboard'])) // Cambia '/success-page' con la tua route di destinazione
    ),
    { dispatch: false }
  );
}

