<!-- <mat-sidenav-container class="sidenav-container" style="height: 100%;">
  <mat-sidenav #sidenav [mode]="'side'" [(opened)]="sidenavOpened">
    <mat-nav-list>
      <a mat-list-item>Profilo Utente</a>
      <a mat-list-item (click)="logout()">Logout</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Dashboard</span>
    </mat-toolbar>
  </mat-sidenav-content>
</mat-sidenav-container> -->
<div *ngIf="!(products$ | async)?.loading!"  style="margin:50px">
  <h1>DASHBOARD</h1>
  <!-- <mat-card>
    <mat-card-header>
      <mat-card-title>Dati fornitore</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Nome: {{ fornitore.nome }}</p>
      <p>Numero di Importazioni Fatte: {{ fornitore.numeroImportazioni }}</p>
      <p>Numero di Articoli Inseriti: {{ fornitore.numeroArticoliInseriti }}</p>
    </mat-card-content>
  </mat-card> -->
  <div *ngFor="let fornitore of (products$ | async)?.fornitoriInfo" style="margin-top: 10px;">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Dati fornitore</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Codice Fornitore: {{ fornitore.codiceFornitore }}</p> <!-- Presumo che 'nome' sia inteso come 'email' -->
        <p>Email: {{ fornitore.email }}</p> <!-- Presumo che 'nome' sia inteso come 'email' -->
        <p>Numero di Importazioni Fatte: {{ fornitore.numeroImportazioni }}</p>
        <p>Numero di Articoli Inseriti: {{ fornitore.numeroArticoliInseriti }}</p>
        <!-- <p>Ruoli: {{ fornitore.ruoli }}</p> -->
      </mat-card-content>
    </mat-card>
  </div>


  <hr />
  <button (click)="downloadExcelProdotti()">Download Excel Prodotti</button>
</div>

