
<div mat-dialog-content>

  <div class="centered-content">
    <h2>{{data.title}}</h2>
    <mat-icon [ngClass]="{
        'custom-info-icon': data.messageType === MessageBoxType.INFO,
        'custom-warning-icon': data.messageType === MessageBoxType.WARNING,
        'custom-error-icon': data.messageType === MessageBoxType.ERROR,
        'custom-success-icon': data.messageType === MessageBoxType.SUCCESS,
        'custom-help-icon': data.messageType === MessageBoxType.YES_NO
      }" aria-hidden="false" [attr.aria-label]="data.messageType.toString()">{{data.messageType.toString()}}</mat-icon>
  </div>
  <p>{{data.content}}</p>
</div>

<div *ngIf="data.messageType != MessageBoxType.YES_NO" mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Chiudi</button>
</div>

<div *ngIf="data.messageType == MessageBoxType.YES_NO"  mat-dialog-actions >
  <button mat-button [mat-dialog-close]="'confirm'">Conferma</button>
  <button mat-button [mat-dialog-close]="'cancel'">Annulla</button>
</div>