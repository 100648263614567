
<!-- file-upload.component.html -->
<div class="file-upload-wrapper">
  <div class="instructions-container">
    <h2>Istruzioni per il Caricamento del File</h2>
    <p>Il file Excel carica solo il primo foglio e deve essere formattato in maniera semplice e ordinata senza campi nascosti, pivot o formule, altrimenti non è garantito il caricamento corretto dei dati.</p>
    <p>Il file deve essere composto obbligatoriamente da 3 colonne: <b>CODICE EAN - DESCRIZIONE - INCI</b></p>
    <p>Una volta caricato premere il bottone <b>IMPORTA DATI</b></p>
    <p>Nel caso in cui il file sia composto da più colonne, selezionare dal menu a tendina i campi obbligatori e premere <b>IMPORTA DATI</b></p>
  </div>



  <button mat-raised-button color="primary" (click)="fileInput.click()">
    Carica File Excel
  </button>
  <input type="file" #fileInput (change)="onFileChange($event)" accept=".xlsx, .xls" hidden>
  <br>

     <!-- Zona per visualizzare i campi selezionati -->
     <div class="fields-container" *ngIf="(rows.length>0) && maxSize>3">
      <h3>Campi obbligatori da selezionare:</h3>
      <ul>
        <li *ngFor="let opzione of opzioni" [ngClass]="{'selected': opzione.selezionato, 'not-selected': !opzione.selezionato}">
          <span class="material-icons">
            {{opzione.selezionato ? 'check_circle' : 'cancel'}}
          </span>
          {{opzione.descr}}
        </li>
      </ul>
    </div>

  <button *ngIf="importAvailable" color="accent" mat-raised-button (click)="generateJsonFromSelections()">
    Importa Dati
  </button>
<br>

</div>
<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <p>Caricamento del file e anteprima del file Excel in corso...</p>
</div>
<div *ngIf="(rows.length>0)" class="table-scroll-container" role="main">
  <mat-checkbox *ngIf="(maxSize>3)" (change)="toggleIntestazione()">File con intestazione</mat-checkbox>
  <table class="fixed-height-table" border="1">
    <thead *ngIf="(maxSize>3)">
      <tr>
        @for(i of counter(maxSize); track i) {
          <th>

            <mat-form-field appearance="fill">
              <mat-label>Seleziona un'opzione</mat-label>
              <mat-select (selectionChange)="onSelectChange(i + 1, $event)" [value]="''">
                <mat-option value="">Nessuna selezione</mat-option>
                @for(opzione of opzioni; track opzione) {
                  <mat-option [value]="opzione.value" [disabled]="opzione.selezionato">
                    {{ opzione.descr }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </th>
        }
      </tr>
      <tr>
        @for(i of counter(maxSize); track i) {
          <th>Colonna {{rows[0][i]}}</th>
        }
      </tr>
    </thead>
    <tbody>
    @for(row of rows; track $index) {
      <tr>
        @for(cell of row; track $index) {
          <td>{{cell}}</td>
        }
      </tr>
    }
    </tbody>
  </table>
</div>
