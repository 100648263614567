<!-- custom-mat-table.component.html -->
<mat-paginator
#paginator
[length]="recordCount"
[pageSizeOptions]="pageSizes"
showFirstLastButtons
(page)="page.emit($event)"
[hidePageSize]="hidePageSize"

>
</mat-paginator>
<table mat-table [dataSource]="dataSource!" class="mat-elevation-z8 custom-table">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
      <td mat-cell *matCellDef="let element" (click)="column.click?.(element)" [ngClass]="column.cellClass ? column.cellClass(element) : ''"> {{column.cell(element)}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row [ngClass]="{'selected-row': row === selectedElement}" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

