import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatFooterCell } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageBoxType } from '../../state/products/products.state';
import { Store } from '@ngrx/store';
import { navigate } from '../../state/route/route.actions';


@Component({
  selector: 'app-message-box',
  standalone: true,
  imports: [MatCard,MatCardHeader, MatCardTitle, MatCardSubtitle, MatCardActions, MatButton, MatCardContent, MatDivider, MatCardFooter, MatProgressBar,MatIcon, CommonModule],
  templateUrl: './message-box.component.html',
  styleUrl: './message-box.component.css'
})



export class MessageBoxComponent {
  @Input() title: string = "Messaggio di avviso";
  @Input() content: string = "Procedura avvenuta con successo fra pochi secondi potrai effettuare una nuova operazione.";
  @Input() subtitle: string = "";
  @Input() redirectUri: string = "/dashboard";
  @Input() isLoading: boolean = true;
  @Input() queryParams?: object|undefined;
  @Input() messageType: MessageBoxType = MessageBoxType.INFO;
  @Input() autoRedirectMilliseconds: number = 0;

  //test:string="info";
  //ACCROCCO PER FAR VEDERE ENUM All'HTML
    MessageBoxType = MessageBoxType;
  //private store: Store, 
  constructor( private store:Store) {

  }
  private timeoutId: any; // Per tenere traccia dell'identificativo del timeout
  buttonClick()
  {
     // Interrompe il timeout quando il pulsante viene cliccato
     if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.store.dispatch(navigate({ path: [this.redirectUri],queryParams: this.queryParams}));
      //this.routernavigate([this.redirectUri]);
  }

  ngOnInit() {

    if (this.autoRedirectMilliseconds! > 0) {
      this.timeoutId = setTimeout(() => {
        this.store.dispatch(navigate({ path: [this.redirectUri], queryParams: this.queryParams}));
      }, this.autoRedirectMilliseconds);
    }


  }


}
