import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle, MatCardActions, MatCardContent, MatCardFooter } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MessageBoxType } from '../../state/products/products.state';



@Component({
  selector: 'app-custom-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose, MatButton,

    MatCard,MatCardHeader,MatCardTitle, MatCardSubtitle,MatCardActions, MatCardContent, MatDivider, MatCardFooter, MatProgressBar,MatIcon, CommonModule],
  //  imports: [MatDialogTitle, MatDialogContent],
  templateUrl: './custom-dialog.component.html',
  styleUrl: './custom-dialog.component.css'
})
export class CustomDialogComponent {

  MessageBoxType = MessageBoxType; // Espone l'enum al template

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    messageType: MessageBoxType,
    title: string,
    content: string,
    redirectUri: string
  }) {



    
  }

}
