import { MatCard } from '@angular/material/card';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ProductsTableComponent } from "../../components/products-table/products-table.component";
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { UploadExcelComponent } from "../../components/upload-excel/upload-excel.component";
import { UploadHistoryTableComponent } from '../../components/upload-history-table/upload-history-table.component';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { selectProductsState } from '../../state/products/products.reducers';
import { Observable } from 'rxjs';
import { ProductsState, MessageBoxType } from '../../state/products/products.state';
import { AddProductsComponent } from "../../components/add-products/add-products.component";
import { MatCardModule } from '@angular/material/card';
import { TableUtil } from '../../services/table-utils.service';
import { ProductsService } from '../../services/products.service';
import { loadFornitoriInfo } from '../../state/products/products.actions';



@Component({
    selector: 'app-dashboard',
    standalone: true,
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.css',
    imports: [RouterModule, CommonModule, MatToolbarModule, MatIconModule,
        MatButtonModule, MatMenuModule, MatSidenavModule, MatListModule,
        ProductsTableComponent, UploadExcelComponent, UploadHistoryTableComponent,
        MessageBoxComponent, AddProductsComponent, MatCardModule]
})
export class DashboardComponent  {
    //ACCROCCO PER FAR VEDERE ENUM All'HTML
    MessageBoxType = MessageBoxType;
  products$:Observable<ProductsState>
  
  
  // fornitore = {
  //   nome: 'Mario Rossi',
  //   numeroImportazioni: 5,
  //   numeroArticoliInseriti: 150
  // };


  constructor(private store: Store,  private router: Router, private productsService:ProductsService  ) {

      this.products$ = this.store.select(selectProductsState);
  }

  downloadExcelProdotti(): void {
    this.productsService.exportData('ArticoliUpload').subscribe({
      next: (data) => {
        TableUtil.exportArrayToExcel(data.content, 'ArticoliUpload', true);
      },
      error: (error) => {
        console.error('Download failed:', error);
      }
    });
  }

 
  ngOnInit() {
      this.store.dispatch(loadFornitoriInfo());
  }


  // sidenavOpened = true; // Gestisce lo stato del sidenav

  // toggleSidenav() {
  //   this.sidenavOpened = !this.sidenavOpened; // Cambia lo stato del sidenav
  // }

  // logout() {
  //   // Qui implementi la logica di logout
  //   thisrouternavigate(["/login"])
  //   console.log('Utente disconnesso');
  // }
}
