import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Observable, Subscription, map, startWith, switchMap } from 'rxjs';
import {  selectProductsState } from '../../state/products/products.reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { loadArticoli, loadStoricoArticoli, loadStoricoUpload, resetArticoli } from '../../state/products/products.actions';
import { ProductsState, DataUploadClass, HistoryUploadClass, MessageBoxType } from '../../state/products/products.state';
import { CustomMatTableComponent, TableColumn } from '../custom-mat-table/custom-mat-table.component';
import { UtilsService } from '../../services/utils.service';
import { PageEvent } from '@angular/material/paginator';
import { updateFlagIsValid } from '../../state/products/products.actions';
import { DialogService } from '../../services/dialog-service.service';

@Component({
  selector: 'app-upload-history-table',
  standalone: true,
  //imports: [MatPaginator,MatProgressBarModule,MatTableModule,MatTableModule,CommonModule],

  imports: [CustomMatTableComponent, CommonModule],
  templateUrl: './upload-history-table.component.html',
  styleUrl: './upload-history-table.component.css'
})
export class UploadHistoryTableComponent {

  @Output() infoSelezionata = new EventEmitter<string>();
  //currentSelectedInfo:string = "";
  products$ : Observable<ProductsState>;
  selectedElement:any;
  recordCount:number = 0; // Numero totale di record, da ottenere da qualche parte
  //myDataSource = new MatTableDataSource(ELEMENT_DATA); //['id', 'ean', 'descrizione', 'inc', 'fornitore', 'dataUpload'];
  myColumns: TableColumn[] = [
    {
      columnDef: 'idImport',
      header: 'Id',
      cell: (element: any) => `${element.idImport}`,
      click: (element: any) => this.onCellClick(element)
    },
    {
      columnDef: 'dataUpload ',
      header: 'Data',
      cell: (element: any) => `${this.utilsService.formatDate(element.dataUpload)}`,
      click: (element: any) => this.onCellClick(element)
    },
 {
      columnDef: 'flagIsValid',
      header: 'Valido',

      cell: (element: any) => `${element.flagIsValid ? 'Sì' : 'No'}`,
      cellClass: (element: any) => element.flagIsValid ? 'valid-class' : 'invalid-class',
      click: (element: any) => this.updateFlagIsValid(element)
    }

    // {
    //   columnDef: 'totalItems',
    //   header: 'Elementi',
    //   cell: (element: any) => `${element.totalItems}`,
    //   click: (element: any) => this.onCellClick(element)
    // }
  ];

  handlePageEvent(event: PageEvent) {
    //console.log(event);
    // Qui puoi gestire l'evento, ad esempio caricare nuovi dati in base alla pagina corrente
    this.store.dispatch(loadStoricoUpload({ perPage: event.pageSize, page: event.pageIndex + 1 }));
  }

  constructor(private store: Store, private router: Router, private utilsService: UtilsService, private dialogService:DialogService) {
    this.products$ = this.store.select(selectProductsState);
  }

    dataSource = new MatTableDataSource<HistoryUploadClass>();
    private subscription = new Subscription();

    updateFlagIsValid(element: any){
      this.dialogService.openDialog({
        messageType:   MessageBoxType.YES_NO,
        title: "Conferma Operazione", 
        content:  `Si sicuro di voler cambiare la validità dell'importazione effettuata il ${this.utilsService.formatDate(element.dataUpload,'it-IT', "dd/MM/yyyy hh:mm")}? `, 

        }).subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(updateFlagIsValid({idImport:element.idImport, flagIsValid:!element.flagIsValid }))
        } else {
          console.log("L'azione non è stata confermata.");
        }
        });
     
    }

    onCellClick(element:any)
    {
      //console.log(element);
      this.selectedElement = element;
      let selectedInfo:string = `Articoli caricati il ${this.utilsService.formatDate(element.dataUpload,'it-IT', "dd/MM/yyyy HH:mm")}`
      this.infoSelezionata.emit(selectedInfo);
      this.store.dispatch(loadStoricoArticoli({ perPage:10, page: 1, filter : {idImport:element.idImport}}));

    }

    ngOnInit() {
      this.subscription = this.products$.subscribe(data => {
        this.dataSource = new MatTableDataSource(data.HistoryUploadData.data);
        this.recordCount = data.HistoryUploadData.recordCount
      });
      let pageSize:number = 10;
      if (this.dataSource.paginator)
      {
         pageSize = this.dataSource.paginator!.pageSize;
      }
      this.store.dispatch(resetArticoli());
      this.store.dispatch(loadStoricoUpload({ perPage:pageSize, page: 1}));

      this.infoSelezionata.emit("NO_PRODUCTS");



    }

    ngOnDestroy() {
      // Annulla la sottoscrizione quando il componente viene distrutto
      this.subscription.unsubscribe();
    }
}


