import { Component } from '@angular/core';
import { CustomMatTableComponent, TableColumn } from '../custom-mat-table/custom-mat-table.component';
import { CommonModule } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { ProductsState, DataUploadClass } from '../../state/products/products.state';
import { PageEvent } from '@angular/material/paginator';
import { loadStoricoArticoli } from '../../state/products/products.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectProductsState } from '../../state/products/products.reducers';
import { MatTableDataSource } from '@angular/material/table';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-upload-history-products',
  standalone: true,
  imports: [CustomMatTableComponent, CommonModule],
  templateUrl: './upload-history-products.component.html',
  styleUrl: './upload-history-products.component.css'
})
export class UploadHistoryProductsComponent {


  products$ : Observable<ProductsState>;
  recordCount:number = 0; // Numero totale di record, da ottenere da qualche parte
  idImportFilter: string = ""
  //myDataSource = new MatTableDataSource(ELEMENT_DATA); //['id', 'ean', 'descrizione', 'inc', 'fornitore', 'dataUpload'];
  myColumns: TableColumn[] = [
    {
      columnDef: 'id',
      header: 'Id.',
      cell: (element: any) => `${element.id}`,
      //click: (element: any) => this.onCellClick(element, 'position')
    },
    {
      columnDef: 'codiceEan',
      header: 'EAN',
      cell: (element: any) => `${element.codiceEan}`,
    },
    {
      columnDef: 'descrizione',
      header: 'Descrizione',
      cell: (element: any) => `${element.descrizione}`,
    },
    {
      columnDef: 'inci',
      header: 'INCI',
      cell: (element: any) => `${element.inci}`,
    },
    {
      columnDef: 'fornitore',
      header: 'Fornitore',
      cell: (element: any) => `${element.fornitore}`,
    },
    {
      columnDef: 'dataUpload',
      header: 'Upload',
      cell: (element: any) => `${this.utilsService.formatDate(element.dataUpload)}`,
    }
    ];

    handlePageEvent(event: PageEvent) {
      this.store.dispatch(loadStoricoArticoli({ perPage: event.pageSize, page: event.pageIndex + 1, filter: {idImport:this.idImportFilter }}));
    }

    constructor(private store: Store, private router: Router, private utilsService: UtilsService) {
      this.products$ = this.store.select(selectProductsState);
    }

    dataSource = new MatTableDataSource<DataUploadClass>();
    private subscription = new Subscription();

    ngOnInit() {
        this.subscription = this.products$.subscribe(data => {
        this.dataSource = new MatTableDataSource(data.historyUploadProducts.data);
        this.recordCount = data.historyUploadProducts.recordCount
        this.idImportFilter = data.productDataFilter.idImport!

      });
      let pageSize:number = 10;
      if (this.dataSource.paginator)
      {
         pageSize = this.dataSource.paginator!.pageSize;
      }
      //this.store.dispatch(loadStoricoArticoli({ perPage:pageSize, page: 1,idImport:''}));
    }

    ngOnDestroy() {
      // Annulla la sottoscrizione quando il componente viene distrutto
      this.subscription.unsubscribe();
    }
}
