<div class="app-container">
<mat-toolbar color="primary">
  <button *ngIf="(auth$ | async)?.isLogged" mat-icon-button (click)="toggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button>
  <span>Vendor Hub</span>
  <!-- Eventuali altri elementi della toolbar -->
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" style="height: 100%;">

    <mat-sidenav *ngIf="(auth$ | async)?.isLogged" #sidenav [mode]="'side'" [(opened)]="sidenavOpened">
      <mat-nav-list>
        <a mat-list-item routerLink="/dashboard">Dashboard</a>
        <a mat-list-item routerLink="/import">Import</a>
        <a mat-list-item routerLink="/history">Caricamenti</a>
        <a mat-list-item routerLink="/products">Articoli</a>
        <a mat-list-item (click)="logout()">Logout</a>
        <!-- Altre opzioni del menu -->
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>


        <router-outlet></router-outlet>

      <!-- Router Outlet per il contenuto specifico della pagina       <pre>{{ auth$ | async | json }}</pre> -->

    </mat-sidenav-content>
  </mat-sidenav-container>
    <!-- Footer -->
    <footer class="footer">
      <span>Developed by <a href="https://www.mcdatasolution.com/" target="_blank">MCDataSolutions SRL</a></span>
      <br>
      <!--img  style="width: 100px;" src="../../../assets/logo.svg" alt="LOGO MELONI"-->
        <br>
        &copy; 2024
    </footer>
</div>
