import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { HistoryComponent } from './pages/history/history.component';
import { ProductsComponent } from './pages/products/products.component';
import { authGuard } from './guards/auth.guard';
import { ImportComponent } from './pages/import/import.component';
import { ErrorComponent } from './pages/error/error.component';

// export const routes: Routes = [
//   { path: 'login', component: LoginComponent },
//   { path: 'dashboard', component: DashboardComponent },
//   { path: '**', component: LoginComponent },
// ];


export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [authGuard]},
      { path: 'history', component: HistoryComponent, canActivate: [authGuard] },
      { path: 'products', component: ProductsComponent, canActivate: [authGuard] },
      { path: 'import', component: ImportComponent, canActivate: [authGuard] },
      { path: 'error/:code', component: ErrorComponent },
      { path: '', component: LoginComponent},
      { path: '**', component: ErrorComponent },

      // Aggiungi altre rotte figlie qui
    ]
  }
];
