import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; // Aggiorna il percorso secondo necessità
import { CustomDialogComponent } from '../components/custom-dialog/custom-dialog.component';
import { MessageBoxDetails, MessageBoxType } from '../state/products/products.state';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { navigate } from '../state/route/route.actions';



@Injectable({
  providedIn: 'root'
})


//ESEMPIO DIALOG SERVICE YES NO
// this.dialogService.openDialog({
//   messageType:   MessageBoxType.YES_NO,
//   title: "Conferma Operazione", 
//   content: "Si sicuro di voler confermare questra procedura?", 
//   //redirectUri : "/products",
//   //autoCloseMilliseconds:3000
//   }).subscribe(confirmed => {
//   if (confirmed) {
//     console.log("L'azione è stata confermata.");
//     // Esegui logica post-conferma qui
//   } else {
//     console.log("L'azione non è stata confermata.");
//     // Esegui logica alternativa qui
//   }
//   });

export class DialogService {

  constructor(private dialog: MatDialog, private store:Store) { }

  
  openDialog(messageBoxDetails: MessageBoxDetails): Observable<boolean> {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '500px',
      data: {
        messageType: messageBoxDetails.messageType,
        title: messageBoxDetails.title,
        content: messageBoxDetails.content,
        redirectUri: messageBoxDetails.redirectUri
      }
    });

    if (messageBoxDetails.autoCloseMilliseconds! > 0) {
      setTimeout(() => {
        dialogRef.close();
      }, messageBoxDetails.autoCloseMilliseconds);
    }



    if (messageBoxDetails.messageType != MessageBoxType.YES_NO)
    {
        dialogRef.afterClosed().subscribe(result => {
          //console.log('finestra chiusa');   
          if ((messageBoxDetails.redirectUri ?? "").trim() !== "") {
            this.store.dispatch(navigate({ path: [messageBoxDetails.redirectUri] }));
          }
        });

        return  new Observable<boolean>(observer => {   
          observer.next(false);
          observer.complete();        
        })
    }

    return new Observable<boolean>(observer => {
      
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
          //console.log('L\'utente ha confermato l\'azione.');
          observer.next(true);
          observer.complete();      
          if ((messageBoxDetails.redirectUri ?? "").trim() !== "") {
            this.store.dispatch(navigate({ path: [messageBoxDetails.redirectUri] }));
          }  
        } else {
          //console.log('L\'utente ha annullato l\'azione o ha chiuso il dialogo.');
          observer.next(false);
          observer.complete();         
        }

      });
    });
  }
}

  /*
  openDialog(messageBoxDetails:MessageBoxDetails) {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '500px',
      data: {
        messageType: messageBoxDetails.messageType,
        title: messageBoxDetails.title, // "Messaggio di avviso",
        content: messageBoxDetails.content, //"Procedura avvenuta con successo fra pochi secondi potrai effettuare una nuova operazione.",
        redirectUri: messageBoxDetails.redirectUri //dashboard"
   
      }
    });

    if (messageBoxDetails.autoCloseMilliseconds! > 0)
    {
      // Chiudi il dialogo automaticamente dopo 5 secondi (5000 millisecondi)
      setTimeout(() => {
        dialogRef.close();
      }, messageBoxDetails.autoCloseMilliseconds);
    }


    


    //QUANDO CHIUDE IL DIALOG BOX
    dialogRef.afterClosed().subscribe(result => {     

      if (result === 'confirm') {
        console.log('L\'utente ha confermato l\'azione.');
        // Esegui azioni basate sulla conferma qui
        if ((messageBoxDetails.redirectUri  ?? "").trim() !== "") {      
          this.routernavigate([messageBoxDetails.redirectUri]);
        }
      } else if (result === 'cancel') {
        console.log('L\'utente ha annullato l\'azione.');
        // Gestisci l'annullamento qui
      } else {
        // L'utente ha chiuso il dialogo in altro modo (es. cliccando fuori dal dialogo)
        console.log('Dialogo chiuso senza azioni specifiche.');
      }

      //console.log(`Dialog result: ${result}`);      
      if ((messageBoxDetails.redirectUri  ?? "").trim() === "") {      
        return;
      }
      
      this.routernavigate([messageBoxDetails.redirectUri]);
    });
  }*/
