import { Component } from '@angular/core';
import { UploadHistoryTableComponent } from '../../components/upload-history-table/upload-history-table.component';
import { UploadHistoryProductsComponent } from '../../components/upload-history-products/upload-history-products.component';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { Store } from '@ngrx/store';
import { loadStoricoArticoli, updateFlagIsValid } from '../../state/products/products.actions';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-history',
  standalone: true,
  imports:  [FormsModule, MatFormFieldModule, MatInputModule,UploadHistoryTableComponent, UploadHistoryProductsComponent, MatCard, MatCardTitle, MatCardContent],
  templateUrl: './history.component.html',
  styleUrl: './history.component.css'
})
export class HistoryComponent {

  searchTextInciStoricoUpload = '';
  
  searchTextEanStoricoUpload = '';

testUpdateIsValid() {
  this.store.dispatch(updateFlagIsValid({idImport:1, flagIsValid:false }))
}
  selectedInfo:string="Articoli";
  constructor(private store: Store, private searchService: SearchService) {


    this.searchService.getFilteredSearch().subscribe(({ field, value }) => {
        
    //console.log(field);

    if (field == "storico_inci")
    {
      if (value.length > 2) {
        // Dispatch dell'azione con il testo filtrato per i testi più lunghi di 2 caratteri
        this.store.dispatch(loadStoricoArticoli({ perPage:10, page: 1, filter : { inci:value }}));
      } else {
        // Dispatch di un'altra azione quando il testo è vuoto
        this.store.dispatch(loadStoricoArticoli({ perPage:10, page: 1, filter : { inci:"" }}));
      }
    }

    if (field == "storico_ean")
    {
      if (value.length > 2) {
        // Dispatch dell'azione con il testo filtrato per i testi più lunghi di 2 caratteri
        this.store.dispatch(loadStoricoArticoli({ perPage:10, page: 1, filter : { codiceEan:value }}));
      } else {
        // Dispatch di un'altra azione quando il testo è vuoto
        this.store.dispatch(loadStoricoArticoli({ perPage:10, page: 1, filter : { codiceEan:"" }}));
      }
    }
  });


  }
  OnChangeIdImport(info: string) {
    console.log('Informazione ricevuta dal componente figlio:', info);
    this.selectedInfo = info;
    //this.informazioneSelezionata = info;
    // Qui puoi fare altre operazioni con l'informazione ricevuta
  }

  
  updateSearch(field: string, value: string): void {
    this.searchService.addSearchCriteria(field, value);
  }
}
