import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponseLogin } from '../state/auth/auth.state';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  baseUri =`${environment.apiUrlVendorHub}/api/`;
  authenticate(username: string, password: string) {
    return this.http.post<ApiResponseLogin>(`${this.baseUri}Token/Authenticate`, {
      username,
      password
    });
  }
}
