import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardImage, MatCardModule } from '@angular/material/card';
import { Store } from '@ngrx/store';
import { login, logout } from '../../state/auth/auth.actions';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { CustomDialogComponent } from '../../components/custom-dialog/custom-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogService } from '../../services/dialog-service.service';
import { ProductsState, MessageBoxType } from '../../state/products/products.state';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatDivider } from '@angular/material/divider';
import { selectProductsState } from '../../state/products/products.reducers';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { selectAuthData } from '../../state/auth/auth.reducer';
import { AuthState } from '../../state/auth/auth.state';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatButtonModule, MatInputModule, MatCardModule, FormsModule,MessageBoxComponent,
    MatProgressBar,MatDivider,MatCardImage,
    CustomDialogComponent, CommonModule
    ,MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  auth$:Observable<AuthState>
  constructor(private store: Store, private dialogService: DialogService) {
    this.auth$ = this.store.select(selectAuthData);
    console.log(environment);
    //rimuovo accessToken
    //localStorage.setItem('accessToken', "");
    this.store.dispatch(logout());
    //this.dialogService.openDialog(MessageBoxType.SUCCESS, "TEST", "Procedura avvenuta con successo fra pochi secondi potrai effettuare una nuova operazione.", "/dashboard" );
  }

  login(): void {
    
    
   // ESEMPIO DIALOG SERVICE YES NO
// this.dialogService.openDialog({
//   messageType:   MessageBoxType.YES_NO,
//   title: "Conferma Operazione", 
//   content: "Si sicuro di voler confermare questra procedura?", 
//   //redirectUri : "/products",
//   //autoCloseMilliseconds:3000
//   }).subscribe(confirmed => {
//   if (confirmed) {
//     console.log("CONFERMATA");
//     // Esegui logica post-conferma qui
//   } else {
//     console.log("ANNULLATA");
//     // Esegui logica alternativa qui
//   }
//   });
    
     this.store.dispatch(login({ username: this.username, password: this.password }));
  }


}
