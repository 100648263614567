import { createAction, props } from '@ngrx/store';
import { ApiFornitoriInfoResponse, DataUploadClass, HistoryUploadData, HistoryUploadProducts, ProductData, ProductSearchFiltersClass, StoricoUploadResponse, storicoArticoloDataTableClass } from './products.state';

export const loadArticoli = createAction(
  '[Products] Load data Articoli',
    props<{ perPage:number, page:number, filter:ProductSearchFiltersClass}>()
);


// Azione per aggiornare i filtri di ricerca
export const updateArticoliSearchFilters = createAction(
  '[Search Component] Update Product Search Filters',
  props<{ filters: ProductSearchFiltersClass }>()
);



export const loadArticoliSuccess = createAction(
    '[Products] Load data articoli success',
    props<{ result: ProductData}>()
);


export const loadStoricoArticoli = createAction(
  '[Products] Load storico Articoli',
    props<{ perPage:number, page:number, filter:ProductSearchFiltersClass }>()
);


export const loadStoricoArticoliSuccess = createAction(
  '[Products] Load data storico success',
  props<{ result:HistoryUploadProducts }>()
);


export const uploadArticoliRequest = createAction(
  '[Products] Upload Articoli Request',
  props<{ articoli: any[] }>()
);

export const uploadArticoliSuccess = createAction(
  '[Products] Upload Articoli Success',
  props<{ response: any }>()
);

export const loadStoricoUploadSuccess = createAction(
  '[Products] Load storico upload Success',
  props<{ response: HistoryUploadData }>()
);

export const loadStoricoUpload = createAction(
  '[Products] Load storico upload',
  props<{ perPage:number, page:number }>()
);


export const resetArticoli = createAction(
  '[Products] Reset Articoli',
);


export const loadStoricoArticoloDataTable = createAction(
  '[Products] Load storico articolo data table',
  props<{ storicoArticoloDataTable:storicoArticoloDataTableClass }>()
);


export const updateFlagIsValid = createAction(
  '[Products] Update Flag IsValid',
  props<{ idImport: number, flagIsValid: boolean
  }>()
);


export const updateFlagSuccess = createAction(
  '[Products] Update Flag Success',
  props<{ idImport: number, flagIsValid: boolean
  }>()
);


export const loadFornitoriInfo = createAction(
  '[Products] Load Fornitori Info'
);

export const loadFornitoriInfoSuccess = createAction(
  '[Products] Load Fornitori Success',
    props<{ response: ApiFornitoriInfoResponse }>()
  );

