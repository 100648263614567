import { InputDialogComponent } from './../input-dialog/input-dialog.component';
import { Component, inject } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { selectProductsState } from '../../state/products/products.reducers';
import { Observable } from 'rxjs';
import { ProductsState } from '../../state/products/products.state';
import { Store } from '@ngrx/store';
import { uploadArticoliRequest } from '../../state/products/products.actions';

interface Articolo {
  codiceEan: string;
  descrizione: string;
  inci: string;
}

@Component({
  selector: 'app-add-products',
  standalone: true,
  imports: [MatTableModule, MatDialogModule, InputDialogComponent, FormsModule, MatIconModule, MatButtonModule],
  templateUrl: './add-products.component.html',
  styleUrl: './add-products.component.css'
})
export class AddProductsComponent {

  displayedColumns: string[] = ['codiceEan', 'descrizione', 'inci', 'azioneCancella'];
  dataSource: Articolo[] = [];
  isDialogOpen: boolean = false;

  dashboard$ : Observable<ProductsState>;

  constructor(public dialog: MatDialog,
              private store: Store) {
                this.dashboard$ = this.store.select(selectProductsState);
              }

  aggiungiRiga(): void {
    this.dataSource = [...this.dataSource, { codiceEan: '', descrizione: '', inci: '' }];
  }

  generateJsonFromSelections(): { articoli: Articolo[] } {
    const jsonConNome = { articoli: this.dataSource };
    console.log(jsonConNome);

    // Invia il JSON agli articoli store
    this.store.dispatch(uploadArticoliRequest(jsonConNome));

    return jsonConNome;
  }

  rimuoviRiga(elementoDaRimuovere: any) {
    const data = this.dataSource;
    const index = data.indexOf(elementoDaRimuovere);
    if (index > -1) {
      data.splice(index, 1);
      this.dataSource = [...data]; // Riassegna i dati per aggiornare la tabella
    }
  }

  apriDialogInci(element: Articolo, inputElement: HTMLInputElement): void {

    if (this.isDialogOpen) {
      return;
    }

    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '500px',
      data: { inci: element.inci }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        element.inci = result;
      }
      this.isDialogOpen = false;
      inputElement.blur(); // Rimuove il focus dall'input
    });
  }
}
