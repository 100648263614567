

<app-custom-mat-table
  class="hide-label-range-label clickable-table"
  [dataSource]="dataSource"
  [columns]="myColumns"
  [recordCount]="recordCount"
  [pageSizes]="[10, 15, 20]"
  (page)="handlePageEvent($event)"
  [isLoading]="(products$ | async)?.loading!"
  [hidePageSize]="true"
  [selectedElement]="selectedElement"
></app-custom-mat-table>
<!--

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container [matColumnDef]="column" *ngFor="let column of columns">
      <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
      <td mat-cell *matCellDef="let emp">{{ emp[column] }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let emprow; columns: columns"></tr>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="(dashboard$ | async)?.loading"></mat-progress-bar>
  <mat-paginator
    #paginator
    [length]="totalData"
    [pageSizeOptions]="pageSizes"
    (page)="handlePageEvent($event)"
    showFirstLastButtons
  >
</mat-paginator>

-->
