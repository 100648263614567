import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchSubject: Subject<{ field: string; value: string }> = new Subject();

  constructor() {}

  // Aggiunge i dati di ricerca al Subject
  addSearchCriteria(field: string, value: string): void {
    this.searchSubject.next({ field, value });
  }

  // Ottiene un Observable che emette i dati di ricerca filtrati
  getFilteredSearch(): Observable<{ field: string; value: string }> {
    return this.searchSubject.pipe(
      debounceTime(300),
      // Assicurati che il valore inserito sia di almeno 3 caratteri o il campo sia vuoto prima di procedere.
      //filter(({ value }) => value.length > 2 || value.length === 0),
      // Usa switchMap per cancellare l'Observable precedente e sottoscriversi a uno nuovo basato sui valori correnti di 'field' e 'value'.
      switchMap(({ field, value }) => {
        // Qui puoi inserire la logica per effettuare la richiesta di ricerca effettiva o altre operazioni con i valori filtrati.
        // Per semplicità, restituiamo direttamente l'oggetto mappato, ma qui dovresti sostituire questo con la tua logica di richiesta/filtraggio effettiva.
        return of({ field, value: value.trim() }); // 'of' è usato solo come placeholder. Sostituiscilo con la tua logica di chiamata/filtraggio.
      })
    );
  }
}
