import { selectAuthData } from './../../state/auth/auth.reducer';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsTableComponent } from '../../components/products-table/products-table.component';
import { UploadHistoryTableComponent } from '../../components/upload-history-table/upload-history-table.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { ProductsUploadsTableComponent } from "../../components/products-uploads-table/products-uploads-table.component";
import { Store } from '@ngrx/store';
import { selectProductsState } from '../../state/products/products.reducers';
import { Observable, Subject, debounceTime, filter } from 'rxjs';
import { ProductsState, StoricoUpload } from '../../state/products/products.state';
import { loadArticoli, loadStoricoArticoli, loadStoricoArticoloDataTable } from '../../state/products/products.actions';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SearchService } from '../../services/search.service';
@Component({
    selector: 'app-products',
    standalone: true,
    templateUrl: './products.component.html',
    styleUrl: './products.component.css',
    imports: [FormsModule, MatFormFieldModule, MatInputModule, ProductsTableComponent, UploadHistoryTableComponent, MatGridListModule, MatCard, MatCardTitle, MatCardContent, CommonModule, ProductsUploadsTableComponent]
})
export class ProductsComponent {


    currentDataStoricoUpload:StoricoUpload[];
    searchTextInciStoricoUpload = '';
    searchProductEAN = '';
    searchProductINCI = '';

    private searchSubjectInciStoricoUpload: Subject<string> = new Subject();

    products$:Observable<ProductsState>
    constructor(private store: Store, private searchService: SearchService) {

      this.currentDataStoricoUpload = [];
      this.products$ = this.store.select(selectProductsState);
    
    

      // Sottoscrizione all'observable con debounce
      // this.searchService.getFilteredSearch().subscribe(({ field, value }) => {
      //   if (value.length > 2 || value.length === 0) {
      //     // Qui puoi dispatchare le tue azioni basate su `field` e `value`
      //     // ad esempio, this.store.dispatch(...)
      //   }
      // });

      this.searchService.getFilteredSearch().subscribe(({ field, value }) => {
        
          console.log(field);



        if (field == "storico_inci")
        {
          if (value.length > 2) {
            // Dispatch dell'azione con il testo filtrato per i testi più lunghi di 2 caratteri
            this.store.dispatch(loadStoricoArticoloDataTable({ storicoArticoloDataTable:{ data:this.currentDataStoricoUpload, filter: { inci:value } } }));
          } else {
            // Dispatch di un'altra azione quando il testo è vuoto
            this.store.dispatch(loadStoricoArticoloDataTable({  storicoArticoloDataTable:{ data:this.currentDataStoricoUpload, filter: { inci:"" } } }))
          }
        }

        if (field == "product_ean")
        {
          if (value.length > 2) {
            // Dispatch dell'azione con il testo filtrato per i testi più lunghi di 2 caratteri
            this.store.dispatch(loadArticoli({page:1, perPage:10, filter: { codiceEan:value, idImport: "" } }));
          } else {
            // Dispatch di un'altra azione quando il testo è vuoto
            this.store.dispatch(loadArticoli({page:1, perPage:10, filter: { codiceEan:"", idImport: "" } }));
          }
        }

        if (field == "product_inci")
        {
          if (value.length > 2) {
            // Dispatch dell'azione con il testo filtrato per i testi più lunghi di 2 caratteri
            this.store.dispatch(loadArticoli({page:1, perPage:10, filter: { inci:value} }));
          } else {
            // Dispatch di un'altra azione quando il testo è vuoto
            this.store.dispatch(loadArticoli({page:1, perPage:10, filter: { inci:"" } }));
          }
        }





      });


    // this.searchSubjectInciStoricoUpload.pipe(
    //   debounceTime(300), // Aspetta 300ms dopo l'ultimo evento prima di procedere
    // ).subscribe(filteredText => {
    //   if (filteredText.length > 2) {
    //     // Dispatch dell'azione con il testo filtrato per i testi più lunghi di 2 caratteri
    //     this.store.dispatch(loadStoricoArticoloDataTable({ storicoArticoloDataTable:{ data:this.currentDataStoricoUpload, filter: { inci:filteredText } } }));
    //   } else {
    //     // Dispatch di un'altra azione quando il testo è vuoto
    //     this.store.dispatch(loadStoricoArticoloDataTable({  storicoArticoloDataTable:{ data:this.currentDataStoricoUpload, filter: { inci:"" } } }))
    //   }
    //   // Puoi gestire qui eventuali altre logiche per lunghezze di testo intermedie
    // });
}

  // onSearchChangeStoricoUpload(): void {
  //   // Invia il testo attuale al Subject
  //   this.searchSubjectInciStoricoUpload.next(this.searchTextInciStoricoUpload);
  // }


  selectedInfo:string="Articoli";
  selectData: any;

  OnChangeIdImport(info: string) {
    //console.log('Informazione ricevuta dal componente figlio:', info);
    this.selectedInfo = info;
    //this.informazioneSelezionata = info;
    // Qui puoi fare altre operazioni con l'informazione ricevuta
  }

  OnClick(data:any){

    this.searchTextInciStoricoUpload = "";
    this.currentDataStoricoUpload = data;
    //console.log(data);
    //let test = {storicoArticoloDataTable:{ data:data, filter: { inci:"" } }};
    this.store.dispatch(loadStoricoArticoloDataTable({  storicoArticoloDataTable:{ data:data, filter: { inci:"" } } }))

    //this.selectData = data;
  }


  updateSearch(field: string, value: string): void {
    this.searchService.addSearchCriteria(field, value);
  }


  ngOnInit() {

  }

}
