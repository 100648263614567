import { Component, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { Observable, tap } from 'rxjs';
import { selectProductsState } from '../../state/products/products.reducers';
import { ProductsState } from '../../state/products/products.state';
import { AuthState } from '../../state/auth/auth.state';
import { selectAuthState } from '../../state/auth/auth.reducer';
import { BrowserModule } from '@angular/platform-browser';
import { navigate } from '../../state/route/route.actions';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    RouterModule, // Necessario per 'routerLink' e '<router-outlet>'
    CommonModule,

  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent implements OnInit{

 
  auth$ : Observable<AuthState>;
  //isInLoginPage: boolean = false;

  constructor(private store: Store,  private router: Router) {

    this.auth$  = this.store.select(selectAuthState);

    this.auth$.pipe(
      //tap(auth => console.log(auth))
    ).subscribe();

  }

  ngOnInit(): void {
    // Controlla se l'URL corrente è quello della pagina di login
    // console.log('router',this.router.url)
    // console.log(this.router.url.indexOf('error'));
    // this.isInLoginPage = (this.router.url === '/login' || this.router.url === '/' || this.router.url.indexOf('error')>0);

    // // Opcional: Puoi anche usare router.events per reagire ai cambiamenti di navigazione
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.isInLoginPage = event.url === '/login';
    //   }
    // });
  }

  sidenavOpened = true; // Gestisce lo stato del sidenav

  toggleSidenav() {
    this.sidenavOpened = !this.sidenavOpened; // Cambia lo stato del sidenav
  }

  logout() {
    // Qui implementi la logica di logout
    //this.routernavigate(["/login"])
    this.store.dispatch(navigate({ path: ['/login'] }));
    //console.log('Utente disconnesso');
  }

}
