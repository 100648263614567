// In effects/error.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { handleError } from './error.actions';

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions, private snackBar: MatSnackBar) {}

  handleError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleError),
      tap(({ error, exception }) => {

        console.error(JSON.stringify(exception));
        this.snackBar.open(error, 'OK', {
          duration: 5000, // Imposta la durata secondo le tue preferenze
        });
      })
    ),
    { dispatch: false } // Questo Effect non dispatcha nuove azioni
  );
}