<app-custom-mat-table
  class="hide-label-range-label clickable-table"
  [dataSource]="dataSource"
  [columns]="myColumns"
  [recordCount]="recordCount"
  [pageSizes]="[10, 25, 50]"
  (page)="handlePageEvent($event)"
  [isLoading]="(products$ | async)?.loading!"
  [hidePageSize]="true"
></app-custom-mat-table>
