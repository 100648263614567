import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  constructor() { }

  // Metodo per formattare date
  formatDate(dateInput: string | Date, locale: string = 'it-IT', dateFormat: string = 'dd/MM/yyyy'): string {
    const date = (typeof dateInput === 'string') ? new Date(dateInput) : dateInput;
    return new Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  }

  // Qui puoi aggiungere altri metodi di utilità
}
