<div *ngIf="!(products$ | async)?.loading!"  style="margin:50px">
  <div class="instructions-container">
    <h2>Importa Dati Articoli</h2>
    <div *ngIf="importMode === ''">
    <p>
      In questa pagina è possibile caricare gli articoli. <br>
      Selezionare la modalità di caricamento. <br>
      <b>Caricamento manuale</b> per inserire i prodotti manualmente per poi caricarli a sistema<br>
      <b>Caricamento da file</b> per caricaricare i dati da un file excel e caricarli a sistema
    </p>

    <button mat-raised-button color="primary" (click)="setImportMode('manuale')">Caricamento Manuale</button>
    <button mat-raised-button color="accent" (click)="setImportMode('file')">Caricamento File</button>
    </div>
    <button mat-raised-button *ngIf="importMode !== ''" (click)="resetImportMode()">Resetta pagina di import</button>
  </div>
  <div *ngIf="importMode === 'file'">
    <app-upload-excel></app-upload-excel>
  </div>

  <div *ngIf="importMode === 'manuale'">
    <app-add-products></app-add-products>
  </div>
</div>


<app-message-box
          *ngIf="(products$ | async)?.loading!"
          [messageType]="MessageBoxType.INFO"
          title="Caricamento in corso..."
          content="La procedura potrebbe richiedere qualche minuto dipende dal traffico e dalla dimensione del file, attendere l'esito dell'operazione.">
</app-message-box>
