import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from '../state/auth/auth.state';
import { selectAuthState } from '../state/auth/auth.reducer';
import { first, map, take } from 'rxjs';
import { navigate } from '../state/route/route.actions';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,) => {
   // console.log('eccomi')
    const router = inject(Router);
    const store = inject(Store<AuthState>);

    //console.log(store)
    store.select(selectAuthState).pipe(
      first(),
      map(authState => {
        if(authState.isLogged){
          return true;
        }
        console.log('not logged')
        //routernavigate(['/login']);
         store.dispatch(navigate({ path: ['/login'] }));
        return false;
      })
    ).subscribe()
  return true;
};
