import { Component } from '@angular/core';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { MessageBoxType } from '../../state/products/products.state';
import { ErrorItemClass, ErrorItems } from '../../models/error.models';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-error',
  standalone: true,
  imports: [MessageBoxComponent],
  templateUrl: './error.component.html',
  styleUrl: './error.component.css'
})

export class ErrorComponent {
  MessageBoxType = MessageBoxType;
  redirectUri:string = "dashboard";
  errorCode:string="";
  errorItem:ErrorItemClass = {
      title : "Errore 404 Pagina non trovata ",
      message: "La pagina che stai cercando non esiste. Controlla l'URL e riprova.",
      code: "404"
  }


  constructor(private route: ActivatedRoute) {}

  ngOnInit() {

    console.log(this.route.snapshot.paramMap.get('code')!);
    // Ottieni il parametro 'id' dalla route, gestiscilo come nullabile
    const error:string= this.route.snapshot.paramMap.get('code')?? "404";
    //this.queryParams = {error};
    this.errorItem = ErrorItems[error] || {
      code: "???",
      title: "Errore Sconosciuto",
      message: "Si è verificato un errore non gestito."
    };
  }

}
