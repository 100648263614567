<h1 mat-dialog-title>INCI</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Incolla INCI qui</mat-label>
    <textarea matInput
              [(ngModel)]="data.inci"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="10"
              cdkAutosizeMaxRows="30"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data.inci">Ok</button>
  <button mat-button (click)="onNoClick()">Annulla</button>
</div>
