import { createAction, props } from '@ngrx/store';
import { User } from './auth.state';

export const login = createAction(
  '[Auth API] Login',
  props<{ username: string; password: string }>()
);

export const logout = createAction(
  '[Auth API] Logout'
);

export const loginSuccess = createAction(
  '[Auth API] Login Success',
  props<{ user: User }>()
);

export const loginFailure = createAction(
  '[Auth API] Login Failure',
  props<{ error: string }>()
);

export const showSnackbar = createAction(
  '[Auth] Show Snackbar',
  props<{ message:string, messageType:string }>()
);

